import React from "react"
import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"
import underlineImg from "../images/landing/underline-scribble.svg"

const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Header>
        <h1>Terms and Conditions</h1>
        <p>
          Conditions of service
          <span />
        </p>
      </Header>
      <Content>
        <p>
          Build my Book is committed to protecting your privacy and developing
          technology that gives you the most powerful and safe online
          experience. This Statement of Privacy applies to the Build my Book Web
          site and governs data collection and usage. By using the Build my Book
          website, you consent to the data practices described in this
          statement.
        </p>
        <p>
          <strong>
            <span className="SubHead">
              Collection of your Personal Information
            </span>
          </strong>
        </p>
        <p>
          Build my Book collects personally identifiable information, such as
          your e-mail address, name, home or work address or telephone number.
          Build my Book also collects anonymous demographic information, which
          is not unique to you, such as your ZIP code, age, gender, preferences,
          interests and favorites.
        </p>
        <p>
          There is also information about your computer hardware and software
          that is automatically collected by Build my Book. This information can
          include: your IP address, browser type, domain names, access times and
          referring Web site addresses. This information is used by Build my
          Book for the operation of the service, to maintain quality of the
          service, and to provide general statistics regarding use of the Build
          my Book Web site.
        </p>
        <p>
          Please keep in mind that if you directly disclose personally
          identifiable information or personally sensitive data through Build my
          Book public message boards, this information may be collected and used
          by others. Note: Build my Book does not read any of your private
          online communications.
        </p>
        <p>
          Build my Book encourages you to review the privacy statements of Web
          sites you choose to link to from Build my Book so that you can
          understand how those Web sites collect, use and share your
          information. Build my Book is not responsible for the privacy
          statements or other content on Web sites outside of the Build my Book
          and Build my Book family of Web sites.
        </p>
        <p>
          <strong>
            <span className="SubHead">Use of your Personal Information</span>
          </strong>
        </p>
        <p>
          Build my Book collects and uses your personal information to operate
          the Build my Book Web site and deliver the services you have
          requested. Build my Book also uses your personally identifiable
          information to inform you of other products or services available from
          Build my Book and its affiliates. Build my Book may also contact you
          via surveys to conduct research about your opinion of current services
          or of potential new services that may be offered.
        </p>
        <p>
          Build my Book does not sell, rent or lease its customer lists to third
          parties. Build my Book may, from time to time, contact you on behalf
          of external business partners about a particular offering that may be
          of interest to you. In those cases, your unique personally
          identifiable information (e-mail, name, address, telephone number) is
          not transferred to the third party. In addition, Build my Book may
          share data with trusted partners to help us perform statistical
          analysis, send you email or postal mail, provide customer support, or
          arrange for deliveries. All such third parties are prohibited from
          using your personal information except to provide these services to
          Build my Book, and they are required to maintain the confidentiality
          of your information.
        </p>
        <p>
          Build my Book does not use or disclose sensitive personal information,
          such as race, religion, or political affiliations, without your
          explicit consent.
        </p>
        <p>
          Build my Book keeps track of the Web sites and pages our customers
          visit within Build my Book, in order to determine what Build my Book
          services are the most popular. This data is used to deliver customized
          content and advertising within Build my Book to customers whose
          behavior indicates that they are interested in a particular subject
          area.
        </p>
        <p>
          Build my Book Web sites will disclose your personal information,
          without notice, only if required to do so by law or in the good faith
          belief that such action is necessary to: (a) conform to the edicts of
          the law or comply with legal process served on Build my Book or the
          site; (b) protect and defend the rights or property of Build my Book;
          and, (c) act under exigent circumstances to protect the personal
          safety of users of Build my Book, or the public.
        </p>
        <p>
          <strong>
            <span className="SubHead">Use of Cookies</span>
          </strong>
        </p>
        <p>
          The Build my Book Web site use “cookies” to help you personalize your
          online experience. A cookie is a text file that is placed on your hard
          disk by a Web page server. Cookies cannot be used to run programs or
          deliver viruses to your computer. Cookies are uniquely assigned to
          you, and can only be read by a web server in the domain that issued
          the cookie to you.
        </p>
        <p>
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. The purpose of a cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize Build my Book pages, or register with Build my Book site
          or services, a cookie helps Build my Book to recall your specific
          information on subsequent visits. This simplifies the process of
          recording your personal information, such as billing addresses,
          shipping addresses, and so on. When you return to the same Build my
          Book Web site, the information you previously provided can be
          retrieved, so you can easily use the Build my Book features that you
          customized.
        </p>
        <p>
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies, you may not be able to fully experience the interactive
          features of the Build my Book services or Web sites you visit.
        </p>
        <p>
          <strong>
            <span className="SubHead">
              Security of your Personal Information
            </span>
          </strong>
        </p>
        <p>
          Build my Book secures your personal information from unauthorized
          access, use or disclosure. Build my Book secures the personally
          identifiable information you provide on computer servers in a
          controlled, secure environment, protected from unauthorized access,
          use or disclosure. When personal information (such as a credit card
          number) is transmitted to other Web sites, it is protected through the
          use of encryption, such as the Secure Socket Layer (SSL) protocol.
        </p>
        <p>
          <strong>
            <span className="SubHead">Changes to this Statement</span>
          </strong>
        </p>
        <p>
          Build my Book will occasionally update this Statement of Privacy to
          reflect company and customer feedback. Build my Book encourages you to
          periodically review this Statement to be informed of how Build my Book
          is protecting your information.
        </p>
        <p>
          <strong>
            <span className="SubHead">Contact Information</span>
          </strong>
        </p>
        <p>
          Build my Book welcomes your comments regarding this Statement of
          Privacy. If you believe that Build my Book has not adhered to this
          Statement, please contact Build my Book at{" "}
          <a href="mailto:clientcare@qphoto.co.za">clientcare@qphoto.co.za</a>.
          We will use commercially reasonable efforts to promptly determine and
          remedy the problem.
        </p>
        <p>
          <b>Consent for Processing Personal Information</b>
        </p>
        <p>
          Consent for Processing Personal Information I understand that Build my
          Book requires certain Personal Information to be able to process
          my/our orders and consent to Build my Book processing and sharing this
          with third parties, where and only where this is necessary. Such third
          parties may include Aramex etc. Build my Book undertakes to only use
          Personal Information in accordance with the POPI Act.
        </p>
        <p></p>
      </Content>
    </PageWrapper>
  )
}

const Header = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  p {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  span {
    background-image: url(${underlineImg});
    background-repeat: no-repeat;
    background-position: 50%;
    height: 8px;
    display: block;
    width: 100%;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.6rem;
      position: absolute;
      left: 50%;
      transform: rotate(-2deg);
    }
    span {
      transform: translateX(15px);
      background-position: right;
    }
  }
`

const Content = styled.div`
  padding-top: 50px;
  p {
    padding-bottom: 15px;
    color: ${props => props.theme.color.grey};
    &.bold {
      padding: 0;
      font-family: ${props => props.theme.font.galanoBold};
    }
    &.title {
      color: ${props => props.theme.color.dark};
      padding: 0;
      font-family: ${props => props.theme.font.galanoBold};
    }
  }
  .paragraphs {
    padding-bottom: 15px;
    .list {
      padding-left: 10px;
      padding-bottom: 15px;
      p {
        padding: 0;
        text-indent: -5px;
        &.bold {
          &::before {
            content: "";
          }
        }
        &::before {
          content: "-";
          text-indent: -5px;
        }
      }
    }
  }
`

export default PrivacyPolicy
